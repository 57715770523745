import React from 'react'
import { control } from 'react-validation'
import Textarea from 'react-textarea-autosize'
import styles from './TextareaAutoSize.module.scss'

export interface TextareaAutoSizeProps {
  error: React.ReactNode
  isChanged?: boolean
  label?: string
  isUsed?: boolean
  className?: string
}

const TextareaAutoSize = ({
  error,
  isChanged,
  isUsed,
  className,
  label,
  ...props
}: TextareaAutoSizeProps) => {
  let innerClassName = 'textarea-autosize'
  if (isChanged && isUsed && error) {
    innerClassName += ' is-invalid-input'
  }

  return (
    <div className={styles.textareaWrapper}>
      {label && <label className={styles.textareaLabel}>{label}</label>}
      <Textarea
        className={`${innerClassName} ${className ? className : ''}`}
        {...props}
      />
      {isChanged && isUsed && error ? (
        <div className="field-error">{error}</div>
      ) : (
        ''
      )}
    </div>
  )
}

export default control(TextareaAutoSize)
