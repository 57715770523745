import React, { Children, ReactNode, cloneElement, useEffect, useRef, useState } from 'react'
import style from './BboxImage.module.scss'
import MagnifyImage from '../MagnifyImage/MagnifyImage'
import { ITaskResultValues } from 'components/Task/types/OcrMultiple/OcrMultiple'

export interface RecognizeStatsItem {
  id?: string
  label: string
  value: string
  coords: number[][]
  confidence: number
  confidence_is_low: boolean
  page_num?: number
  page_height?: number
  page_width?: number
  edited?: boolean
}

export interface BboxImageProps {
  crop?: string
  hoveredItemName: string | null
  items?: RecognizeStatsItem[],
  initHeight?: number,
  initWidth?: number,
  onImgError?: () => void,
  children?: ReactNode,
  isPageLoaded?: Boolean
  taskResultValues?: ITaskResultValues
}

export default function BboxImage(props: BboxImageProps) {
  const imageRef = useRef<HTMLImageElement>(null)
  const pageRef = useRef<any>(null)
  const [pageWithRef, setPageWithRef] = useState(null)

  useEffect(() => {
    if (props.children) {
      const child = Children.only(props.children);
      // @ts-ignore
      const pageWithRef = cloneElement(child, { ref: pageRef });
      setPageWithRef(pageWithRef)
    }
  }, [])

  return (
    <div className={style.root}>
      {
        props.crop && <>
					<img
						ref={imageRef}
						src={props.crop}
						alt=''
						className={style.image1}
						onError={props.onImgError}
					/>
					<MagnifyImage
						src={props.crop}
						imageClassName={style.image}
					/>
				</>
      }
      {
        props.children && <div>
          {pageWithRef}
				</div>
      }
    </div>
  )
}
