import React, { Component, Fragment, SyntheticEvent } from 'react'
import Combokeys from 'combokeys'
import style from './Ocr.module.css'
import './style.scss'
// import MagnifyImage from '../../../Task/components/magnifyImage/MagnifyImage'
import Bttn from '../../../Bttn'
import ButtonSubmit from '../../../Button/ButtonSubmit'
import Effects from '../../../Effects'
import Form from '../../../Form'
import Field from '../../../Form/field'
import TextareaAutoSize from '../../../Form/TextareaAutoSize'
import MagnifyImage from 'components/MagnifyImage/MagnifyImage'
import { isStudioMode } from 'env'

const bodyClass = ['label-text-page']

export interface OcrProps {
  tasks: any
  task: any
  t: any
  markTask: any
  skipTask: any
  testLabel?: React.ReactNode
  fullDescriptionPopup?: React.ReactNode
  caption?: React.ReactNode
  description?: React.ReactNode
}

class Ocr extends Component<OcrProps, any> {
  combokeys: Combokeys.Combokeys
  form: HTMLFormElement
  payload: any

  constructor(props) {
    super(props)
    this.state = {
      value: (props.task.input.text || [''])[0],
      txtareaError: false,
    }

    this.combokeys = new Combokeys(document.documentElement)
  }

  componentDidMount() {
    document.body.classList.add(...bodyClass)
    this.setComboKeys()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.task.id !== this.props.task.id) {
      //console.log(nextProps)
      this.setState({
        value:
          nextProps.task.userData || (nextProps.task.input.text || [''])[0],
      })
      const textarea: HTMLTextAreaElement | null = document.querySelector(
        '.actions-inner textarea',
      )
      if (textarea) {
        textarea.focus()
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(...bodyClass)
    this.combokeys.detach()
  }

  setComboKeys() {
    this.combokeys.bind('enter', () => {
      this.handleSubmitForm()
    })

    this.combokeys.bind('n', () => {
      this.handleNoText()
    })

    this.combokeys.bind('s', () => {
      this.handleSkip()
    })
  }

  handleKeyDown(event) {
    // Enter
    if (event.which === 13) {
      event.preventDefault()
      this.handleSubmitForm()
    }
  }

  handleChange(event) {
    const { value } = event.target
    this.setState({ value })
  }

  handleSubmitForm(event?: SyntheticEvent) {
    if (event) {
      event.preventDefault()
    }
    const data = this.form.getValues()
    if (!data.payload) {
      this.setState({ txtareaError: true })
      return
    }

    this.handleMark(data.payload)
  }

  handleMark(payload) {
    this.props.markTask(payload, payload)
  }

  handleNoText() {
    const data = this.form.getValues()

    if (data.payload) {
      this.setState({ txtareaError: true })
      return
    }
    this.handleMark('')
  }

  handleSkip() {
    this.props.skipTask()
  }

  render() {
    //console.log(this.props)
    //console.log(this.state)
    const { t, tasks, testLabel, caption, description, fullDescriptionPopup } =
      this.props
    const { value } = this.state

    const skipButton =
      tasks.onboarding_status || isStudioMode ? (
        ''
      ) : (
        <span
          role="button"
          tabIndex={0}
          className="skip"
          onClick={() => this.handleSkip()}
        >
          {t('task.button.skip')}
        </span>
      )

    return (
      <Fragment>
        <div className="annotation row">
          <div className="image-wrapper">
            <div className="image col-4 col-sm-4 col-xs-2">
              <div className="image-value">
                <MagnifyImage
                  src={this.props.task.input.source[0]}
                  imageClassName={style.image}
                />
              </div>
            </div>
          </div>

          <div className="actions-wrapper">
            <div className="actions-outer">
              <div className="actions col-2 col-sm-4 col-xs-2">
                <div className="actions-inner">
                  {testLabel}
                  {caption}
                  {description}
                  <div className="gap-back sm-hidden" />
                  <Form
                    className="textarea-form"
                    onSubmit={this.handleSubmitForm.bind(this)}
                    ref={(c) => {
                      this.form = c
                    }}
                  >
                    <Field className="textarea-wrapper">
                      <Effects
                        transition="shakeShort"
                        transitionFinish={() => {
                          this.setState({
                            txtareaError: false,
                          })
                        }}
                        init={this.state.txtareaError}
                      >
                        <TextareaAutoSize
                          autoFocus
                          type="text"
                          name="payload"
                          ref={(c) => {
                            this.payload = c
                          }}
                          value={value}
                          minRows={2}
                          maxRows={4}
                          style={{ resize: "vertical" }}
                          onChange={this.handleChange.bind(this)}
                          onKeyDown={this.handleKeyDown.bind(this)}
                        />
                      </Effects>
                    </Field>
                    <Field className="buttons field-buttons">
                      <ButtonSubmit className="btn btn-gray">
                        {t('task.button.complete')}
                      </ButtonSubmit>

                      <Bttn
                        onClick={(e) => {
                          e.preventDefault()
                          this.handleNoText()
                        }}
                        type="bordered"
                      >
                        {t('task.button.no_text')}
                      </Bttn>
                    </Field>
                  </Form>
                  <div className="second-actions">{skipButton}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {fullDescriptionPopup}
      </Fragment>
    )
  }
}

export default Ocr
